import React, { useState } from 'react';
import { graphql } from 'gatsby';

import Metadata from '../components/Metadata';

import './product-tour.scss';

export const query = graphql`
  query ProductTourQuery($id: String!) {
    productTour: sanityProductTour(_id: { eq: $id }) {
      _id
      pageTitle
      slug {
        current
      }
      navatticID
      seo {
        metaDescription
        metaTitle
        ogImage {
          asset {
            url
          }
        }
      }
    }
  }
`;

const BasicPage = (props) => {
  const { productTour } = props.data;

  const [isLoading, setIsLoading] = useState(true);

  return (
    <>
      <Metadata
        title={productTour.seo?.metaTitle || productTour.pageTitle}
        description={productTour.seo?.metaDescription}
        image={productTour.seo?.ogImage?.asset.url || null}
        bodyClass="product-tour-page"
      />
      {isLoading && <span className="loader"></span>}
      <iframe
        src={`https://capture.navattic.com/${productTour.navatticID}`}
        style={{ border: 'none', width: '100%', height: '100%', margin: '0' }}
        allow="fullscreen"
        onLoad={() => setIsLoading(false)}
      ></iframe>
    </>
  );
};

export default BasicPage;
